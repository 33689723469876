import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'

import styles from '../styles/LoginQuestionnaire.module.css'
import { InputRadio } from '../components/input/InputRadio'
import { internalExternal, loginType } from '../util/gtm_utils'

export function LoginQuestionnaire({ isFormSubmitted = false }) {
  const [organizationName, setOrganizationName] = useState('')
  const [selectedOption, setSelectedOption] = useState({})
  const [requiredPersonaClassName, setRequiredPersonaClassName] = useState('')
  const [requiredOrganizationClassName, setRequiredOrganizationClassName] =
    useState('')
  const [searchParams] = useSearchParams()
  const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()

  const updateProfile = async (customData) => {
    if (!isAuthenticated) {
      localStorage.removeItem('gtmUserLogin')
      logout({ returnTo: window.location.origin })
    }
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
        scope: 'update:current_user_metadata'
      }
    })
    const userId = user.sub
    const apiUrl =
      process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN ||
      process.env.REACT_APP_AUTH0_DOMAIN

    const response = await fetch(`https://${apiUrl}/api/v2/users/${userId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_metadata: {
          formData: customData
        }
      })
    })

    if (response.status === 200) {
      pushUserProperties(customData)
      window.location.href = `/find-stars?${searchParams.toString()}`
    }
  }

  const handleRadioChange = (e) => {
    setSelectedOption({
      name: e.target.name,
      value: e.target.value
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    let returnStatus = false

    if (!Object.keys(selectedOption).length) {
      returnStatus = true
      setRequiredPersonaClassName(styles.requiredRadio)
    } else {
      setRequiredPersonaClassName('')
    }

    if (organizationName === '') {
      returnStatus = true
      setRequiredOrganizationClassName(styles.requiredText)
    } else {
      setRequiredOrganizationClassName('')
    }

    if (returnStatus) {
      return
    }

    const formData = {
      isFormSubmitted: true,
      dataForm: {
        selectedOption: selectedOption,
        organizationName: organizationName
      }
    }

    updateProfile(formData)
  }

  function pushUserProperties(data) {
    window.dataLayer.push({
      event: 'questionnaire_submission',
      organizationName: data.dataForm.organizationName,
      persona: data.dataForm.selectedOption.value,
      loginType: loginType(user),
      internalExternal: internalExternal(data.dataForm.organizationName)
    })
  }

  return (
    <div style={{ height: '100vh' }}>
      {isFormSubmitted === false ? (
        <div className={styles.wrapperLogin}>
          <main className={styles.main}>
            <div className={styles.content}>
              <img src='/STARSight_TM_RGB_Clr.svg' alt='STARSight Beta' />
              <div className={styles.boxForm}>
                <form onSubmit={handleFormSubmit}>
                  <div className={styles.infoSection}>
                    <strong>
                      Let us know who you are, so we can better cater the site
                      to your needs.
                    </strong>
                    <strong>I work for a...</strong>
                  </div>
                  <div className={styles.inputForm}>
                    <InputRadio
                      name='persona'
                      value='sector_employer'
                      label='Private or public sector employer'
                      selected={selectedOption.value === 'sector_employer'}
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />

                    <InputRadio
                      name='persona'
                      value='public_sector_agency'
                      label='Public sector agency'
                      selected={selectedOption.value === 'public_sector_agency'}
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />

                    <InputRadio
                      name='persona'
                      value='supporting_public_sector'
                      label='Organization supporting the public sector'
                      selected={
                        selectedOption.value === 'supporting_public_sector'
                      }
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />

                    <InputRadio
                      name='persona'
                      value='investor_grant_maker'
                      label='Workforce investor or grant-maker'
                      selected={selectedOption.value === 'investor_grant_maker'}
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />
                    <InputRadio
                      name='persona'
                      value='research_policy_organization'
                      label='Nonprofit research or policy organization'
                      selected={
                        selectedOption.value === 'research_policy_organization'
                      }
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />
                    <InputRadio
                      name='persona'
                      value='another_type_organization'
                      label='I work for another type of organization'
                      selected={
                        selectedOption.value === 'another_type_organization'
                      }
                      onChange={handleRadioChange}
                      requiredClassName={requiredPersonaClassName}
                    />
                  </div>

                  <div className={styles.organizationNameInput}>
                    <strong>What is the name of your organization?</strong>

                    <input
                      className={requiredOrganizationClassName}
                      placeholder='Organization name…'
                      value={organizationName}
                      onChange={(e) => setOrganizationName(e.target.value)}
                    ></input>
                  </div>

                  <button type='submit'>Complete sign-up</button>
                </form>
              </div>
            </div>
          </main>
        </div>
      ) : (
        (window.location.href = localStorage.redirect)
      )}
    </div>
  )
}
