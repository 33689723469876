import React from 'react'
import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@emotion/react'
import { useAuth0 } from '@auth0/auth0-react'

import UserProfileMenu from '../components/login/UserProfileMenu'
import LoginCreateAccount from '../components/login/LoginCreateAccount'
import PageNav from '../pages/PageNav'
import { topNavClick } from 'src/util/gtm_utils'

function TopNav({ showPageNav }) {
  const { isLoading, isAuthenticated } = useAuth0()
  const theme = useTheme()

  const logoLink = !isLoading && isAuthenticated ? '/find-stars' : '/'

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='fixed'
        sx={{
          borderRadius: 0,
          borderBottom: '1px solid #F1F1F1',
          backgroundColor: theme.palette.neutral.white,
          alignItems: 'center',
          boxShadow: 'none',
          marginBottom: '20px'
        }}
      >
        <Container disableGutters={true} sx={{ alignItems: 'left' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              color: 'black'
            }}
          >
            {' '}
            <Toolbar sx={{ width: 1160 }}>
              <Link
                to={logoLink}
                onClick={() => topNavClick('logo', isAuthenticated)}
              >
                <img src='/STARSight_TM_RGB_Clr.svg' alt='STARSight Beta' style={{ width: '225px' }}/>
                {/* <img src='/ss_logo-beta.svg' alt='Stellarsight Beta' /> */}
              </Link>

              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }} />
              {isAuthenticated && <UserProfileMenu />}
              {!isAuthenticated && <LoginCreateAccount />}
            </Toolbar>{' '}
            {isAuthenticated && (
              <Box
                sx={{
                  border: '2px solid rgb(255, 184, 28)',
                  borderRadius: 1,
                  height: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  padding: '8px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    width: '100%',
                    margin: '0 auto',
                    paddingLeft: '40px'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      color: 'black'
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 'bold', fontFamily: 'DM Sans' }}
                    >
                      Stellarsight is now STARSight
                    </Typography>
                    <Typography variant='body2' sx={{ fontFamily: 'DM Sans' }}>
                      We’re updating the name in an effort to streamline our
                      portfolio of tools and make them easier to understand.
                      <a
                        href='https://starsight.zendesk.com/hc/en-us/articles/28458746728845-Introducing-STARSight-and-New-Features'
                        target='blank'
                      >
                        {' '}
                        Read more here.
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {isAuthenticated && showPageNav && <PageNav />}
        </Container>
      </AppBar>
    </Box>
  )
}

export default TopNav
